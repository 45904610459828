import dayjs from '../../dayjs';

const VERSION_DATE_FORMAT = 'YYYY-MM-DD';

const versions = [
  {
    version: '100',
    date: dayjs('2014-8-20', VERSION_DATE_FORMAT)
  },
  {
    version: '107',
    date: dayjs('2014-12-17', VERSION_DATE_FORMAT)
  },
  {
    version: '150',
    date: dayjs('2015-4-20', VERSION_DATE_FORMAT)
  },
  {
    version: '200',
    date: dayjs('2015-8-4', VERSION_DATE_FORMAT)
  },
  {
    version: '250',
    date: dayjs('2015-11-25', VERSION_DATE_FORMAT)
  },
  {
    version: '300',
    date: dayjs('2016-3-16', VERSION_DATE_FORMAT)
  },
  {
    version: '350',
    date: dayjs('2016-7-6', VERSION_DATE_FORMAT)
  },
  {
    version: '360',
    date: dayjs('2016-8-4', VERSION_DATE_FORMAT)
  },
  {
    version: '370',
    date: dayjs('2016-8-30', VERSION_DATE_FORMAT)
  },
  {
    version: '380',
    date: dayjs('2016-9-28', VERSION_DATE_FORMAT)
  },
  {
    version: '390',
    date: dayjs('2016-10-19', VERSION_DATE_FORMAT)
  },
  {
    version: '400',
    date: dayjs('2016-11-14', VERSION_DATE_FORMAT)
  },
  {
    version: '415',
    date: dayjs('2017-1-18', VERSION_DATE_FORMAT)
  },
  {
    version: '420',
    date: dayjs('2017-2-1', VERSION_DATE_FORMAT)
  },
  {
    version: '430',
    date: dayjs('2017-3-1', VERSION_DATE_FORMAT)
  },
  {
    version: '440',
    date: dayjs('2017-4-5', VERSION_DATE_FORMAT)
  },
  {
    version: '450',
    date: dayjs('2017-5-4', VERSION_DATE_FORMAT)
  },
  {
    version: '460',
    date: dayjs('2017-5-24', VERSION_DATE_FORMAT)
  },
  {
    version: '470',
    date: dayjs('2017-6-21', VERSION_DATE_FORMAT)
  },
  {
    version: '475',
    date: dayjs('2017-7-6', VERSION_DATE_FORMAT)
  },
  {
    version: '480',
    date: dayjs('2017-8-31', VERSION_DATE_FORMAT)
  },
  {
    version: '485',
    date: dayjs('2017-9-20', VERSION_DATE_FORMAT)
  },
  {
    version: '490',
    date: dayjs('2017-10-12', VERSION_DATE_FORMAT)
  },
  {
    version: '500',
    date: dayjs('2017-11-9', VERSION_DATE_FORMAT)
  },
  {
    version: '510',
    date: dayjs('2017-12-6', VERSION_DATE_FORMAT)
  },
  {
    version: '520',
    date: dayjs('2018-1-10', VERSION_DATE_FORMAT)
  },
  {
    version: '530',
    date: dayjs('2018-2-1', VERSION_DATE_FORMAT)
  },
  {
    version: '540',
    date: dayjs('2018-3-1', VERSION_DATE_FORMAT)
  },
  {
    version: '550',
    date: dayjs('2018-4-5', VERSION_DATE_FORMAT)
  },
  {
    version: '560',
    date: dayjs('2018-5-3', VERSION_DATE_FORMAT)
  },
  {
    version: '570',
    date: dayjs('2018-6-7', VERSION_DATE_FORMAT)
  },
  {
    version: '580',
    date: dayjs('2018-7-20', VERSION_DATE_FORMAT)
  },
  {
    version: '590',
    date: dayjs('2018-8-15', VERSION_DATE_FORMAT)
  },
  {
    version: '600',
    date: dayjs('2018-9-13', VERSION_DATE_FORMAT)
  },
  {
    version: '610',
    date: dayjs('2018-10-10', VERSION_DATE_FORMAT)
  },
  {
    version: '620',
    date: dayjs('2018-11-7', VERSION_DATE_FORMAT)
  },
  {
    version: '630',
    date: dayjs('2018-12-7', VERSION_DATE_FORMAT)
  },
  {
    version: '640',
    date: dayjs('2019-1-9', VERSION_DATE_FORMAT)
  },
  {
    version: '650',
    date: dayjs('2019-1-30', VERSION_DATE_FORMAT)
  },
  {
    version: '660',
    date: dayjs('2019-2-27', VERSION_DATE_FORMAT)
  },
  {
    version: '670',
    date: dayjs('2019-3-27', VERSION_DATE_FORMAT)
  },
  {
    version: '680',
    date: dayjs('2019-4-25', VERSION_DATE_FORMAT)
  },
  {
    version: '690',
    date: dayjs('2019-5-29', VERSION_DATE_FORMAT)
  },
  {
    version: '700',
    date: dayjs('2019-6-26', VERSION_DATE_FORMAT)
  },
  {
    version: '710',
    date: dayjs('2019-8-7', VERSION_DATE_FORMAT)
  },
  {
    version: '720',
    date: dayjs('2019-9-11', VERSION_DATE_FORMAT)
  },
  {
    version: '730',
    date: dayjs('2019-10-9', VERSION_DATE_FORMAT)
  },
  {
    version: '740',
    date: dayjs('2019-11-6', VERSION_DATE_FORMAT)
  },
  {
    version: '750',
    date: dayjs('2019-12-4', VERSION_DATE_FORMAT)
  },
  {
    version: '760',
    date: dayjs('2020-1-9', VERSION_DATE_FORMAT)
  },
  {
    version: '770',
    date: dayjs('2020-2-5', VERSION_DATE_FORMAT)
  },
  {
    version: '780',
    date: dayjs('2020-3-4', VERSION_DATE_FORMAT)
  },
  {
    version: '790',
    date: dayjs('2020-4-1', VERSION_DATE_FORMAT)
  },
  {
    version: '800',
    date: dayjs('2020-5-1', VERSION_DATE_FORMAT)
  },
  {
    version: '810',
    date: dayjs('2020-6-2', VERSION_DATE_FORMAT)
  },
  {
    version: '820',
    date: dayjs('2020-6-24', VERSION_DATE_FORMAT)
  },
  {
    version: '830',
    date: dayjs('2020-7-22', VERSION_DATE_FORMAT)
  },
  {
    version: '840',
    date: dayjs('2020-8-19', VERSION_DATE_FORMAT)
  },
  {
    version: '850',
    date: dayjs('2020-9-16', VERSION_DATE_FORMAT)
  },
  {
    version: '860',
    date: dayjs('2020-10-14', VERSION_DATE_FORMAT)
  },
  {
    version: '870',
    date: dayjs('2020-11-11', VERSION_DATE_FORMAT)
  },
  {
    version: '880',
    date: dayjs('2020-12-09', VERSION_DATE_FORMAT)
  },
  {
    version: '890',
    date: dayjs('2021-01-13', VERSION_DATE_FORMAT)
  },
  {
    version: '900',
    date: dayjs('2021-02-10', VERSION_DATE_FORMAT)
  },
  {
    version: '910',
    date: dayjs('2021-03-11', VERSION_DATE_FORMAT)
  },
  {
    version: '920',
    date: dayjs('2021-04-08', VERSION_DATE_FORMAT)
  },
  {
    version: '930',
    date: dayjs('2021-05-06', VERSION_DATE_FORMAT)
  },
  {
    version: '940',
    date: dayjs('2021-06-03', VERSION_DATE_FORMAT)
  },
  {
    version: '950',
    date: dayjs('2021-07-01', VERSION_DATE_FORMAT)
  },
  {
    version: '960',
    date: dayjs('2021-07-29', VERSION_DATE_FORMAT)
  },
  {
    version: '970',
    date: dayjs('2021-08-19', VERSION_DATE_FORMAT)
  },
  {
    version: '980',
    date: dayjs('2021-09-16', VERSION_DATE_FORMAT)
  },
  {
    version: '990',
    date: dayjs('2021-10-14', VERSION_DATE_FORMAT)
  },
  {
    version: '10.0.0',
    date: dayjs('2021-11-08', VERSION_DATE_FORMAT)  
  },
  {
    version: '10.1.0',
    date: dayjs('2021-12-09', VERSION_DATE_FORMAT)
  },
  {
    version: '10.2.0',
    date: dayjs('2022-01-13', VERSION_DATE_FORMAT)
  }
];

export const getLatestVersionNumber = () => {
  const lastEntry = versions[versions.length - 1];

  return lastEntry.version.split('').join('.');
};

export default versions;