import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import R from 'ramda';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { auth } from 'seriously-common';
import { extractRoles } from '../auth/utils';

class Title extends Component {
  static propTypes = {
    title: PropTypes.string
  };

  componentDidMount() {
    this.setTitle();
  }

  componentDidUpdate() {
    this.setTitle();
  }

  setTitle = () => {
    const match = this.props.computedMatch || this.props.match;
    let title = this.props.title;
    if (!R.isNil(title) && !R.isNil(match)) {
      for (var key in match.params) {
        title = title.replace('${' + key + '}', match.params[key]);
      }
      document.title = title;
    }
  };

  render() {
    return this.props.children;
  }
}

export const TitledRoute = ({ title, component, render, ...rest }) => {
  if (R.isNil(component) && R.isNil(render)) {
    throw new Error('[TitledRoute] Both component and render props are nil.');
  }
  return (
    <Route
      {...rest}
      render={props => (
        <Title title={title} {...props}>
          {component ? React.createElement(component, props) : render(props)}
        </Title>
      )}
    />
  );
};

export const AuthenticatedRoute = ({ children, component, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={props => {
        !isAuthenticated && console.log('Redirecting!');
        return isAuthenticated ? (
          R.isNil(component) ? (
            children
          ) : (
            React.createElement(component, props)
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              // lastTriedPath stored in local storage by LoginPage.
              state: { lastTriedPath: props.location }
            }}
          />
        );
      }}
    />
  );
};

export const PermissionRoute = ({ component, children, permissions, ...rest }) => {
  const { accounts } = useMsal();
  const roles = extractRoles(accounts);
  const hasPermissions = auth.hasPermissions(roles, permissions);
  if (accounts.length > 0)
    return (
      <TitledRoute
        {...rest}
        render={props =>
          hasPermissions ? (
            R.isNil(component) ? (
              children
            ) : (
              React.createElement(component, props)
            )
          ) : (
            <div style={{ padding: '1em' }}>You do not have permissions to view this page.</div>
          )
        }
      />
    );
};
