import React, { FC, HTMLAttributes } from 'react';
import { Typography, createStyles, withStyles, WithStyles, Tooltip, TypographyProps } from '@material-ui/core';
import * as R from 'ramda';
import VerticalLayout from './VerticalLayout';
import HorizontalLayout from './HorizontalLayout';
import { Error } from '@material-ui/icons';

const styles = createStyles({
  primary: {
    color: '#828282'
  },
  error: {
    color: 'red'
  },
  textIcon: {
    fontSize: '1.3rem'
  },
  details: {
    width: '100%'
  }
});

interface TitledInfoProps extends WithStyles<typeof styles> {
  primary: React.ReactNode;
  secondary: React.ReactNode;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  errorMessage?: string;
}

interface ErrorProps extends WithStyles<typeof styles> {
  errorMessage?: string;
}

interface InfoNodeProps {
  textNodeClassName?: string;
  node: React.ReactNode;
}

interface InfoIconProps extends WithStyles<typeof styles> {
  node?: React.ReactNode;
}

interface InfoActionProps {
  node?: React.ReactNode;
}

const InfoError: FC<ErrorProps> = ({ classes, errorMessage }) => {
  if (R.isNil(errorMessage) || R.isEmpty(errorMessage)) {
    return null;
  }
  return (
    <Error className={classes.error} />
  );
}

const InfoNode: FC<InfoNodeProps & TypographyProps> = ({ textNodeClassName, node, ...rest }) => {
  if (R.is(String, node)) {
    return (
      <Typography {...rest} className={textNodeClassName} variant='subtitle2'>
        {node}
      </Typography>
    )
  }
  
  return <>{node}</>;
}

const InfoIcon: FC<InfoIconProps> = ({ classes, node }) => {
  if (R.isNil(node)) {
    return null;
  }

  const isTextIcon = R.is(String, node);

  return (
    <span className={isTextIcon ? classes.textIcon : ''}>{node}</span>
  )
}

const InfoAction: FC<InfoActionProps> = ({ node }) => {
  if (R.isNil(node)) {
    return null;
  }

  return (
    <>{node}</>
  );
}

const TitledInfo: FC<TitledInfoProps & HTMLAttributes<HTMLDivElement>> = ({
  classes,
  primary,
  secondary,
  icon,
  action,
  errorMessage,
  ...rest
}) => {
  const content = (
    <HorizontalLayout gap={8} alignItems="center">
      <InfoIcon classes={classes} node={icon} />
      <VerticalLayout {...rest} className={!R.isNil(icon) ? classes.details : undefined} alignItems='flex-start'>
        <InfoNode
          variant='subtitle2'
          node={primary}
          textNodeClassName={classes.primary}
        />
        <InfoNode
          variant='body2'
          node={secondary}
          textNodeClassName={R.isNil(errorMessage) ? undefined : classes.error}
        />
      </VerticalLayout>
      <InfoError classes={classes} errorMessage={errorMessage} />
      <InfoAction node={action} />
    </HorizontalLayout>
  );

  if (!R.isNil(errorMessage) && !R.isEmpty(errorMessage)) {
    return <Tooltip title={errorMessage}><span>{content}</span></Tooltip>;
  }

  return content;
};

export default withStyles(styles)(TitledInfo);
