import React, { Component, useMemo, forwardRef } from 'react';
import {
  AppBar,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography,
  ListSubheader,
  Button
} from '@material-ui/core';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import V0MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import PropTypes from 'prop-types';
import R from 'ramda';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { sidebarItems } from '~/config';
import * as authActions from './auth/actions';
import themeConfig, { themeV1 } from './theme';
import ACL from '../core/auth/components/ACL';
import ServerClientVersionValidator from '../core/auth/components/ServerClientVersionValidator';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import SnackbarNotification from './notifications/components/SnackbarNotification';
import './core.scss';
import { withMsal } from '@azure/msal-react';
import { ExitToApp } from '@material-ui/icons';

const theme = createTheme(themeV1);
const themeV0 = getMuiTheme(themeConfig);

const styles = {
  titleBar: {
    marginBottom: '1em'
  },
  appBarTitle: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    flexGrow: 1
  },
  appBarVersion: {
    marginLeft: 16
  },
  flex: {},
  inset: {
    '&:first-child': {
      paddingLeft: 20
    }
  },
  drawerPaper: {
    overflowX: 'hidden',
    width: 280
  }
};

class App extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  state = {
    sidebarOpen: false,
    menuState: {}
  };

  handleNavStateChange = () => this.setState(state => ({ sidebarOpen: !state.sidebarOpen }));
  handleToggleMenuOpen = index => () =>
    this.setState(state => ({
      menuState: R.assoc(index, !state.menuState[index], state.menuState)
    }));

  render() {
    const { classes, msalContext } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <V0MuiThemeProvider muiTheme={themeV0}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <div>
              {this.renderAppBar()}
              <Drawer
                open={this.state.sidebarOpen}
                onClose={this.handleNavStateChange}
                classes={{ paper: classes.drawerPaper }}
              >
                <List>
                  {sidebarItems.map(this.renderNavigationItem)}
                  <Divider />
                  <ListSubheader>Version {DASHBOARD_VERSION}</ListSubheader>
                  <ListItem alignItems='center'>
                    <Button startIcon={<ExitToApp />} variant='outlined' onClick={() => msalContext.instance.logout()}>
                      Log out
                    </Button>
                  </ListItem>
                </List>
              </Drawer>

              {this.props.children}

              <ServerClientVersionValidator />
              <SnackbarNotification />
            </div>
          </MuiPickersUtilsProvider>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    );
  }

  renderAppBar() {
    const { classes } = this.props;
    let title = 'Seriously Dashboards';
    if (DASHBOARD_ENV === 'development') title += ' Dev';
    else if (DASHBOARD_ENV === 'staging') title += ' Staging';
    else if (DASHBOARD_ENV === 'qa') title += ' QA';
    else if (DASHBOARD_ENV === 'production') title += ' Production';
    return (
      <AppBar id='appBar' position='relative' className={classes.titleBar}>
        <Toolbar>
          <IconButton className={classes.menuButton} color='inherit' onClick={this.handleNavStateChange}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' onClick={this.props.navigateToHome} className={classes.appBarTitle}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }

  renderNavigationItem = (item, index) => {
    const { classes } = this.props;
    let child = null;

    if (DASHBOARD_ENV === 'production' && item.developmentOnly === true) return null;

    if (!R.isNil(item.children)) {
      let nested = item.children.map(this.renderNavigationItem);
      child = (
        <span key={index}>
          <ListItem button onClick={this.handleToggleMenuOpen(index)}>
            {!R.isNil(item.icon) && (
              <ListItemIcon>
                <img src={item.icon} style={{ width: 50, height: 50 }} />
              </ListItemIcon>
            )}
            <ListItemText primary={item.name} />
            {this.state.menuState[index] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.menuState[index]} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {nested}
            </List>
          </Collapse>
        </span>
      );
    } else {
      child = (
        <ListItemLink
          to={item.path}
          primary={item.name}
          onClick={this.handleNavStateChange}
          key={index}
          classes={{ inset: classes.inset }}
        />
      );
    }
    return (
      <ACL key={index} require={item.requiredPermissions}>
        {child}
      </ACL>
    );
  };
}
//

let stateToProps = state => ({});

let dispatchToProps = dispatch => ({
  logout: () => {
    dispatch(authActions.logout());
    dispatch(push('/login'));
  },
  navigateToHome: () => dispatch(push('/'))
});

export default connect(
  stateToProps,
  dispatchToProps
)(withMsal(withStyles(styles)(App)));

const ListItemLink = ({ classes, to, icon, primary, onClick }) => {
  const renderLink = useMemo(() => forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />), [to]);

  return (
    <ListItem button onClick={onClick} component={renderLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText inset primary={primary} classes={classes} />
    </ListItem>
  );
};
