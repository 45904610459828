import * as actionTypes from './actionTypes';
import Serializer from '~/bf-common/utils/Serializer';
import R from 'ramda';
import { gameIds, fiends } from '../constants';
import { mapOnGet, mapOnUpdate } from './model';
import { adjustPath } from '~/utils';

let initialEntities = R.reduce(
  (obj, key) => {
    obj[key] = [];
    return obj;
  },
  {},
  gameIds
);

let initialStatuses = R.reduce(
  (obj, key) => {
    obj[key] = {};
    return obj;
  },
  {},
  gameIds
);

const initialState = {
  entities: initialEntities, // Entities that might have been mutated
  serverEntities: { ...initialEntities }, // Entities kept in sync with server
  partialEntities: initialEntities,
  getStatus: initialStatuses,
  getPartialStatus: initialStatuses,
  saveStatus: null,
  copyFromStatus: null,
  dependencies: initialStatuses,
  massOperationStatus: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.GET_PARTIALS:
      return getPartials(state, action);
    case actionTypes.UPDATE:
      return update(state, action);
    case actionTypes.SAVE:
      return save(state, action);
    case actionTypes.REVERT:
      return revert(state, action);
    case actionTypes.ROLLBACK:
      return rollback(state, action);
    case actionTypes.COPY_FROM:
      return copyFrom(state, action);
    case actionTypes.ADD_DEPENDENCY:
      return addDependency(state, action);
    case actionTypes.RELEASE_DEPENDENCY:
      return releaseDependency(state, action);
    default:
      return state;
  }
};

const addDependency = (state, { payload: { seriouslyId, gameId } }) => {
  const dependencies = adjustPath(
    [gameId, seriouslyId],
    R.pipe(
      R.defaultTo(0),
      R.inc
    ),
    state.dependencies
  );
  return {
    ...state,
    dependencies
  };
};

const releaseDependency = (state, { payload: { seriouslyId, gameId } }) => {
  const dependencies = adjustPath(
    [gameId, seriouslyId],
    R.pipe(
      R.defaultTo(0),
      R.dec,
      R.max(0)
    ),
    state.dependencies
  );
  if (dependencies[gameId][seriouslyId] === 0) {
    return {
      ...state,
      dependencies,
      entities: R.evolve(
        {
          [gameId]: R.reject(R.propEq('seriouslyId', seriouslyId))
        },
        state.entities
      ),
      serverEntities: R.evolve(
        {
          [gameId]: R.reject(R.propEq('seriouslyId', seriouslyId))
        },
        state.serverEntities
      )
    };
  } else {
    return {
      ...state,
      dependencies
    };
  }
};

const get = (state, action) => {
  let { gameId, seriouslyId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      getStatus: setStatus(state.getStatus, gameId, seriouslyId, 'pending')
    };
  } else if (action.error) {
    let errorMsg =
      action.payload.message ||
      `Failed to load inventory for ${action.meta.seriouslyId}`;
    if (action.status === 404)
      errorMsg =
        'Player exists, but their inventory cannot be found. Player might not have uploaded their inventory to the cloud.';
    return {
      ...state,
      getStatus: setStatus(state.getStatus, gameId, seriouslyId, errorMsg)
    };
  } else {
    let { data, metadata } = Serializer.deserialize(action.payload);
    let inventory = {
      seriouslyId: action.meta.seriouslyId,
      serializedData: action.payload,
      data,
      metadata
    };
    data = mapOnGet(gameId, data);
    let mappedInventory = {
      seriouslyId: action.meta.seriouslyId,
      serializedData: Serializer.serialize(data, metadata),
      data,
      metadata
    };
    return {
      ...state,
      massOperationStatus: {
        ...state.massOperationStatus,
        [seriouslyId]: false
      },
      entities: {
        ...state.entities,
        [gameId]: addInventory(state.entities[gameId], mappedInventory)
      },
      serverEntities: {
        ...state.entities,
        [gameId]: addInventory(state.serverEntities[gameId], R.clone(inventory))
      },
      getStatus: removeStatus(state.getStatus, gameId, seriouslyId)
    };
  }
};

const getPartials = (state, action) => {
  let { gameId, seriouslyIds } = action.meta;
  if (action.pending) {
    let newGetStatus = {};
    for (const id of seriouslyIds)
      newGetStatus = setStatus(state.getStatus, gameId, id, 'pending');
    return {
      ...state,
      getStatus: newGetStatus
    };
  } else if (action.error) {
    let errorMsg =
      action.payload.message ||
      `Failed to load partial inventory for ${action.meta.seriouslyId}`;
    if (action.status === 404)
      errorMsg =
        'Player exists, but their inventory cannot be found. Player might not have uploaded their inventory to the cloud.';
    let newGetStatus = {};
    for (const id of seriouslyIds)
      newGetStatus = setStatus(state.getStatus, gameId, id, errorMsg);
    return {
      ...state,
      getStatus: newGetStatus
    };
  } else {
    let newGetStatus = {};
    let partialEntities = state.partialEntities;
    let newInventories = [];
    for (const id of seriouslyIds) {
      newGetStatus = removeStatus(state.getStatus, gameId, id);
      if (!R.isNil(action.payload[id])) {
        const { data } = Serializer.deserialize(action.payload[id].parameters);
        const partialInventory = {
          data,
          seriouslyId: id
        };

        newInventories.push(partialInventory);
      }
    }
    partialEntities = {
      ...partialEntities,
      [gameId]: addInventories(partialEntities[gameId], newInventories)
    };

    return {
      ...state,
      partialEntities,
      getStatus: newGetStatus
    };
  }
};

const update = (state, action) => {
  let { gameId } = action.meta;
  let { seriouslyId, data } = action.payload;
  let localIndex = getIndexBySeriouslyId(seriouslyId, state.entities[gameId]);
  data = mapOnUpdate(gameId, state.entities[gameId][localIndex].data, data);
  let inventory = {
    seriouslyId,
    serializedData: Serializer.serialize(
      data,
      state.entities[gameId][localIndex].metadata
    ),
    metadata: state.entities[gameId][localIndex].metadata,
    data
  };
  return {
    ...state,
    massOperationStatus: {
      ...state.massOperationStatus,
      [seriouslyId]: false
    },
    entities: {
      ...state.entities,
      [gameId]: R.update(localIndex, inventory, state.entities[gameId])
    }
  };
};

const rollback = (state, action) => {
  let { gameId } = action.meta;
  let { inventory } = action.payload;
  let { seriouslyId } = inventory;
  let localIndex = getIndexBySeriouslyId(seriouslyId, state.entities[gameId]);

  inventory = specialDelete(
    inventory,
    state.entities[gameId][
      getIndexBySeriouslyId(seriouslyId, state.entities[gameId])
    ]
  );

  return {
    ...state,
    massOperationStatus: {
      ...state.massOperationStatus,
      [seriouslyId]: true
    },
    entities: {
      ...state.entities,
      [gameId]: R.update(localIndex, inventory, state.entities[gameId])
    }
  };
};

const save = (state, action) => {
  let { seriouslyId, gameId, mappedLocalInv } = action.meta;
  if (action.pending) {
    return {
      ...state,
      saveStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      saveStatus:
        action.payload.status || `Failed to save inventory for ${seriouslyId}`
    };
  } else {
    let serverIndex = getIndexBySeriouslyId(
      seriouslyId,
      state.serverEntities[gameId]
    );
    if (serverIndex === -1) {
      console.error(
        `Failed to find server instance of ${seriouslyId}'s inventory after save.'`
      );
      return state;
    }
    let localIndex = getIndexBySeriouslyId(seriouslyId, state.entities[gameId]);
    let inventory = state.entities[gameId][localIndex];
    if (R.isNil(inventory)) {
      console.error('Failed to find local inventory.');
      return state;
    }
    if (!R.isNil(mappedLocalInv)) {
      //if there's a mappedInventory, use that instead of local (that's from time before mapping)
      inventory = mappedLocalInv;
    }
    return {
      ...state,
      massOperationStatus: {
        ...state.massOperationStatus,
        [seriouslyId]: false
      },
      serverEntities: {
        ...state.serverEntities,
        [gameId]: R.update(serverIndex, inventory, state.serverEntities[gameId])
      },
      entities: {
        ...state.entities,
        [gameId]: R.update(localIndex, inventory, state.entities[gameId])
      },
      saveStatus: null
    };
  }
};

const revert = (state, action) => {
  const { gameId } = action.meta;
  const { seriouslyId } = action.payload;
  let serverIndex = getIndexBySeriouslyId(
    seriouslyId,
    state.serverEntities[gameId]
  );
  if (serverIndex === -1) {
    console.error(
      `Failed to find server instance of ${seriouslyId}'s inventory at revert'`
    );
    return state;
  }
  let localIndex = getIndexBySeriouslyId(seriouslyId, state.entities[gameId]);
  let inventory = state.serverEntities[gameId][localIndex];
  return {
    ...state,
    massOperationStatus: {
      ...state.massOperationStatus,
      [seriouslyId]: false
    },
    entities: {
      ...state.entities,
      [gameId]: R.update(localIndex, R.clone(inventory), state.entities[gameId])
    }
  };
};

const copyFrom = (state, action) => {
  const { gameId, toSeriouslyId, fromSeriouslyId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      copyFromStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      copyFromStatus:
        action.payload.status ||
        `Failed to copy inventory from ${fromSeriouslyId}`
    };
  } else {
    let { data, metadata } = Serializer.deserialize(action.payload);
    let inventory = {
      seriouslyId: toSeriouslyId,
      serializedData: action.payload,
      data,
      metadata
    };

    inventory = specialDelete(
      inventory,
      state.entities[gameId][
        getIndexBySeriouslyId(toSeriouslyId, state.entities[gameId])
      ]
    );

    return {
      ...state,
      massOperationStatus: {
        ...state.massOperationStatus,
        [toSeriouslyId]: true
      },
      entities: {
        ...state.entities,
        [gameId]: addInventory(state.entities[gameId], inventory)
      },
      copyFromStatus: null
    };
  }
};

const specialDelete = (inventory, oldInventory) => {
  //when importing inventory, we need to set some fields to explicit values so that they remain removed, or client will recreate them from local storage.
  let changed = false;

  const fiendUniqueNames = getFiendUniqueNames();
  for (let i in fiendUniqueNames) {
    const inventoryName = inventoryFiendName(fiendUniqueNames[i]);
    const existInOld = R.has(inventoryName)(oldInventory.data);
    const existInNew = R.has(inventoryName)(inventory.data);

    //explicitly delete fiends that were in old inventory but not in new one
    if (existInOld && !existInNew) {
      changed = true;
      inventory.data = R.assoc(
        inventoryName,
        {
          fiendLevel: -1,
          fiendUsedForLevelVictory: 0,
          preferredSkinLevel: -1,
          purchasedSkins: 0,
          selected: false
        },
        inventory.data
      );
    }
  }

  //explicitly delete events that were in old inventory but not in new one
  const archiveFilter = (val, key) => key.startsWith('archiveEventsCompleted');
  const oldArchiveEvents = R.pickBy(archiveFilter, oldInventory.data);
  for (let eventName in oldArchiveEvents) {
    const existInOld = R.has(eventName)(oldInventory.data);
    const existInNew = R.has(eventName)(inventory.data);

    if (existInOld && !existInNew) {
      changed = true;
      inventory.data = R.assoc(eventName, 0, inventory.data);
    }
  }

  //remove support ticket id from imported inventory as it can't be closed when the user id has changed.
  if (
    R.pathOr(null, ['customUserData', 'activeSupportTicketId'], inventory.data)
  )
    inventory.data.customUserData.activeSupportTicketId = '';

  if (changed) inventory.serializedData = Serializer.serialize(inventory.data);
  return inventory;
};

/******* Utils  *************/
const addInventory = (inventorys, inventory) =>
  R.unionWith(R.eqBy(R.prop('seriouslyId')), [inventory], inventorys);
const addInventories = (inventories, newInventories) =>
  R.unionWith(R.eqBy(R.prop('seriouslyId')), newInventories, inventories);
const getIndexBySeriouslyId = (seriouslyId, inventorys) =>
  R.findIndex(R.propEq('seriouslyId', seriouslyId), inventorys);

const setStatus = (statuses, gameId, id, status) =>
  R.assocPath([gameId, id], status, statuses);
const removeStatus = (statuses, gameId, id) =>
  R.dissocPath([gameId, id], statuses);

const inventoryFiendName = uniqueName => `Fiend${uniqueName}`;
export const getFiendUniqueNames = () => R.pluck('id', fiends);
