import { useState, useEffect } from 'react';
import Q from 'q';
import * as R from 'ramda';
import { doApiCall } from '../../apiMiddleware';
import useSnackbar from './useSnackbar';
import { msalInstance } from '../../authConfig';
import { auth } from 'seriously-common';
import useUserData, { UserData } from '../auth/hooks/useUserData';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import useRoles from '../auth/hooks/useRoles';

declare const AD_CLIENT_ID: string;

type useRequestProps<T> = [
  response: T | null,
  isPending: boolean,
  doRequest: (request: request, successMessage?: string | null, errorMessage?: string | null) => Promise<T | null>,
  metadata: { transactionId: number }
];

const createHeaders = (data: UserData, headers?: object) => {
  const userData = { id: data.id, email: data.email, name: data.name };
  const encodedUserData = btoa(JSON.stringify(userData));

  return ({
    Accept: 'application/json',
    ['X-DashboardUser']: encodedUserData,
    ...headers
  })
}

function useRequest<T>(): useRequestProps<T> {
  const [ dashboardMetadata, setDashboardMetadata ] = useState({ transactionId: 0 })
  const [ response, setResponse ] = useState(null);
  const [ isPending, setIsPending ] = useState(false);
  const userData = useUserData();
  const roles = useRoles();
  const notify = useSnackbar();
  let isMounted = true;

  const doRequest = async (
    { method, uri, query, body, permissions }: request,
    successMessage?: string | null,
    errorMessage?: string | null) => {

    const hasPermissions = auth.hasPermissions(roles, permissions);

    if (!hasPermissions) {
      notify('You do not have the permission to do that', 'warning');
      return null;
    }

    setIsPending(true);

    const authRequest = {
      scopes: [AD_CLIENT_ID + '/.default']
    };
    
    try {
      if (!msalInstance.getActiveAccount()) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }
      const tokenResponse = await msalInstance.acquireTokenSilent(authRequest);
      const headers = {
        ...createHeaders(userData),
        Authorization: `Bearer ${tokenResponse.accessToken}`
      };

      const response = await doApiCall(method, uri, body, query, headers);
      
      if (!isMounted) {
        return null;
      }

      if (response && response.status >= 400) {
        if (!R.isNil(errorMessage)) {
          notify(errorMessage, 'error')
        }
        
        throw new Error(`Request ${method.toUpperCase()} ${uri} failed with status code ${R.pathOr('Unknown', ['status'], response)}. Error: ${R.pathOr('Unknown', ['statusText'], response)}.`);
      } else {
        const contentType = response.headers.get("content-type");
        let json = {
          payload: null
        };
        
        if (!R.isNil(contentType) && contentType.indexOf("application/json") !== -1) {
          json = await Q(response?.json());
          if (!R.isNil(json)) {
            setResponse(json.payload);
          }
        } else {
          console.warn('The response was not of type JSON. useRequest hook only supports JSON responses');
        }
        
        setDashboardMetadata({ transactionId: dashboardMetadata.transactionId + 1 })

        if (!R.isNil(successMessage)) {
          notify(successMessage, 'info');
        }

        setIsPending(false);
        
        return json.payload;
      }
    } catch (error) {
      console.log(error)
      console.error(error);
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(authRequest);
      }
    }

    setIsPending(false);

    return null;
  }

  useEffect(() => {
    // This sets up a flag to ignore any set state calls that were made on an unmounted component.
    // https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup
    return function cleanup() {
      isMounted = false;
    }
  }, [])

  return [response, isPending, doRequest, dashboardMetadata];
}

export default useRequest;