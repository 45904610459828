import archivedEvents from './archivedEvents.json';
import fiends from './fiends.json';
import fiendQuestOrder from './fiendQuestOrder';
import achievements from './achievements';
import versions, { getLatestVersionNumber } from './versions';
import liveOpsEvents from './liveOpsEvents.json';
import resourceIcons from './resourceIcons';
import countries from './countries';
import locations from './locations';
import germanyStates from './germanyStates';
import russiaStates from './russiaStates';
import usStates from './usStates';
import ukStates from './ukStates';
import collectionEvents from './collectionEvents.json';
import userFlags from './userFlags';
import eventStreamPresets from './eventStreamPresets';

const eventStreamIndex = DASHBOARD_ENV === 'production'
  ? 'game-prod-bf1-*'
  : DASHBOARD_ENV === 'development'
  ? 'game-dev-bf1-*'
  : 'game-qa-bf1-*';

const createVentureEvent = key => ({
  field: `Venture_${key}_fwdCompatible`,
  title: `${key} Goal Number (${getLatestVersionNumber()})`
});

export const NAME = 'bestfiends';
export const gameIds = ['bf'];
export const gameId = 'bf';
export const maxFiendSkins = 10;
export const maxFiendLevel = 50;
export const evolutionSkinCount = 5;
export const levelsInFiendQuest = 30;
export const checklistEventKey = 'Treasure22';
export const checklistEventTitle = `Checklist - ${checklistEventKey || 'Unknown'} (${getLatestVersionNumber() ||
  'Unknown'})`;
export const getVentureEvents = collectionEvents.map(e => createVentureEvent(e.id));
export const battlePassInventoryKey = 'BattlePass_TechTest21_fwdCompatible';

export {
  archivedEvents,
  fiends,
  fiendQuestOrder,
  achievements,
  versions,
  liveOpsEvents,
  resourceIcons,
  countries,
  locations,
  germanyStates,
  russiaStates,
  usStates,
  ukStates,
  userFlags,
  eventStreamPresets,
  eventStreamIndex
};
