import React, { FC } from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';

const SeriouslyIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 2048 2732"{...props}>
      <path d="M978.68,1245.33c0-107.43,214.84-201.95,340.87-237.77c0,0-35.22,254.64,166.46,254.64
	c-14.89-213.9,132.88-201.04,132.88-356.33c0-90.22-80.2-130.33-193.35-130.33c-279.28,0-763.39,240.62-763.39,561.44
	c0,290.76,485.53,173.3,485.53,286.46c0,48.7-123.17,94.52-262.11,94.52c-136.06,0-286.45-44.4-346.6-177.59
	c-67.32,38.67-110.29,116.01-110.29,190.48c0,187.63,249.21,224.87,380.98,224.87c310.8,0,621.6-193.35,621.6-370.96
	C1431.27,1349.87,978.68,1397.14,978.68,1245.33z"/>
    </SvgIcon>
  );
};

export default SeriouslyIcon;