import React, { FC } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { AuthError, InteractionType } from '@azure/msal-browser';

declare const AD_CLIENT_ID: string;

const ErrorComponent: FC<{ error: AuthError | undefined | null }> = ({ error }) => {
  return <p>An Error Occurred: {error}</p>;
};

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

const ADAuthWrapper: FC = ({ children }) => {
  console.log('Auth template', AD_CLIENT_ID);

  return (
    <MsalAuthenticationTemplate
      loadingComponent={LoadingComponent}
      errorComponent={ErrorComponent}
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: [AD_CLIENT_ID + '/.default']
      }}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default ADAuthWrapper;
