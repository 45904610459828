import React, { FC, ReactNode } from 'react';
import Authenticator from './Authenticator';
import TitledInfo from './TitledInfo';

interface AuthenticatedTitledInfoProps {
  permissions: string[];
  icon: ReactNode;
  primary: string;
  secondary: string;
}

const AuthenticatedTitledInfo: FC<AuthenticatedTitledInfoProps> = ({ permissions, icon, primary, secondary }) => {
  const replacement = <TitledInfo icon={icon} primary={primary} secondary='You are not permitted to see this' />;

  return (
    <Authenticator permissions={permissions} replacement={replacement}>
      <TitledInfo icon={icon} primary={primary} secondary={secondary} />
    </Authenticator>
  );
};

export default AuthenticatedTitledInfo;
