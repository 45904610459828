const userFlags = Object.freeze({
	specialEffectSeen: 0,FacebookLikeReward: 1,TwitterFollowReward: 2,InstagramFollowReward: 3,VkFollowReward: 4,
	SnapchatFollowReward: 5,YoutubeFollowReward: 6,openedLeaderboard: 7,specialEffectRewardCollected: 8,oneSpecialDay2018Seen: 9,
	oneSpecialDay2018RewardCollected: 10,AutoSelectFiendsInfoShown: 11,bestFiendsShopSeen: 12,freeStarterPackTaken: 13,bestFiendsShopRewardCollected: 14,
	newsletterPopupSeen: 15,newsletterPermissionGiven: 16,bestFiendsShopBlackFriday18Seen: 17,malariaNoMoreRewardCollected: 18,malariaNoMoreSeen: 19,
	Animation_HowiesGiftPopupShown: 20,Animation_HowiesGiftRewardReceived: 21,Animation_HowiesGiftFBShareRewardReceived: 22,Animation_BootcampRewardReceived: 23,Animation_BootcampPopupShown: 24,
	Animation_BootcampFBShareRewardReceived: 25,Animation_BootcampTWShareRewardReceived: 26,AutoSelectFiendsDisabled: 27,DoubleConfirmationForGoldSink: 28,QAPlayer: 29,
	Animation_VisitMinutiaRewardReceived: 30,Animation_VisitMinutiaPopupShown: 31,Animation_VisitMinutiaFBShareRewardReceived: 32,Animation_VisitMinutiaTWShareRewardReceived: 33,OptOutFromSeriouslyFriends: 34,
	Animation_HowiesGiftTWShareRewardReceived: 35,SeriouslyFriendsInfoShown: 36,infoShownForEarhtwormQuests: 37,infoShownForUniqueFiends: 38,Animation_HowiesGiftQuizCompleted: 39,
	Animation_BootcampQuizCompleted: 40,OptOutFromPublicLeaderboards: 41,notInUse00: 42,BannedFromPublicLeaderboard: 43,PinterestFollowReward: 44,
	DataPrivacyPopUpShownAndApproved_v760: 45,Pride2018RewardCollected: 46,ManuallyVerifiedCheater: 47,Animation_HardKnocksPopupShown: 48,Animation_HardKnocksRewardReceived: 49,
	Animation_HardKnocksFBShareRewardReceived: 50,Animation_HardKnocksTWShareRewardReceived: 51,BanFromSeriouslyFriends: 52,v600cleanupHappened: 53,Animation_BabySlugPopupShown: 54,
	Animation_BabySlugRewardReceived: 55,Animation_BabySlugFBShareRewardReceived: 56,Animation_BabySlugTWShareRewardReceived: 57,ChampionshipMedalsIntroDisplayed: 58,CheckListEventIntrodisplayed: 59,
	OptInToFacebook: 60,SocialMediaPopupSeen: 61,RequireServerTimeCheckForEvents: 62,TimeTravellerImmunity: 63,SeenFriendsCodePopup: 64,
	Animation_HardKnocksQuizCompleted: 65,Animation_BabySlugQuizCompleted: 66,Animation_VisitMinutiaQuizCompleted: 67,AppRatedAlreadyInStore: 68,Animation_TempersAdventurePopupShown: 69,
	Animation_TempersAdventureRewardReceived: 70,Animation_TempersAdventureFBShareRewardReceived: 71,Animation_TempersAdventureTWShareRewardReceived: 72,Animation_TempersAdventureQuizCompleted: 73,EpicFiendDiscountShownV700: 74,
	Animation_ImmortalCockroachPopupShown: 75,Animation_ImmortalCockroachRewardReceived: 76,Animation_ImmortalCockroachFBShareRewardReceived: 77,Animation_ImmortalCockroachTWShareRewardReceived: 78,Animation_ImmortalCockroachQuizCompleted: 79,
	oneSpecialDay2019Seen: 80,oneSpecialDay2019RewardCollected: 81,notInUse01: 82,Animation_FightBeforeChristmasPopupShown: 83,Animation_FightBeforeChristmasQuizCompleted: 84,
	Animation_FightBeforeChristmasFBShareRewardReceived: 85,Animation_FightBeforeChristmasTWShareRewardReceived: 86,Animation_FightBeforeChristmasRewardReceived: 87,SeasonalStylePopupShownXmas2019: 88,Animation_KingSlugIndustriesPopupShown: 89,
	Animation_KingSlugIndustriesQuizCompleted: 90,Animation_KingSlugIndustriesFBShareRewardReceived: 91,Animation_KingSlugIndustriesTWShareRewardReceived: 92,Animation_KingSlugIndustriesRewardReceived: 93,FTUE_NotSet: 94,
	FTUE_DuringLevel: 95,FTUE_FiendQuest1: 96,FTUE_FiendQuest2: 97,FTUE_FiendQuest3: 98,FTUE_FiendQuest4: 99,
	FTUE_Earthworm1: 100,FTUE_Earthworm2: 101,FTUE_Earthworm3: 102,FTUE_Earthworm4: 103,FTUE_EarthwormDifficulty: 104,
	FTUE_FiendMenu1: 105,FTUE_FiendMenu2: 106,FTUE_FiendMenu3: 107,FTUE_FiendMenu4: 108,FTUE_FiendUpgrade1: 109,
	FTUE_FiendUpgrade2: 110,FTUE_FiendUpgradeShortcut: 111,FTUE_5LevelsMapNode: 112,FTUE_BabySlugReward1: 113,FTUE_BabySlugReward2: 114,
	TriggerStickerUnlocking: 115,Facebook_LoginRewardReceived: 116,Facebook_FriendsRewardReceived: 117,BoostModeIntroShown: 118,FTUE_AfterGacha: 119,
	boostModeFreeTrialOffered: 120,boostModeFreeTrialUsed: 121,SeasonalStylePopupShownEaster2020: 122,AppleSignIn_LoginRewardReceived: 123,FTUE_HiddenObject1: 124,
	FTUE_HiddenObject2: 125,FTUE_HiddenObject3: 126,washYourHandsRewardCollected: 127,notInUse02: 128,notInUse03: 129,
	clientDetectedCheater: 130,FTUE_MissingFiendsSign: 131,DisablePurchases: 132,HarderLevels: 133,NewUpdatePromoShownVersion800: 134,
	disableGameplay: 135,notInUse04: 136,FTUE_10LevelsMapNode: 137,showMissingFiendSigns: 138,showMissingFiendSignsAskedToPlayer: 139,
	notInUse05: 140,notInUse06: 141,allowTargetedAdsAsked: 142,disableVIP: 143,giveFreeSpinAfterWheelCrash: 144,
	DataPrivacyPopUpShownAndApproved_v850: 145,SeasonalStylePopupShownHalloween2020: 146,shorterCooldownFor2Keys: 147,RollingOfferReadyForBlackFriday20: 148,SeasonalStylePopupShownXmas2020: 149,
	RollingOfferReadyForXmas20: 150,banPlayer: 151,RollingOfferReadyForVersion900: 152,ForceShowTrackingConsentPrePopup: 153,preLevelBoostersEnabled: 154,
	SeasonalStylePopupShownEaster2021: 155,preLevelBoostersTestOptedIn: 156,showHardLevelTags: 157,preLevelBoostersTestOptinPopupShown: 158,MapReward_SwampVillage_Slug: 159,
	UpgradeFiendsWithGold: 160,preLevelBoostersAllowedToUse: 161,DataPrivacyPopUpShownAndApproved_v920: 162,FTUE_PreLevelBoosterPurchaseWithGold: 163,preLevelBoostersCustomTutorialLevels_v960: 164,
	SkipClientWelcomeBackPopup: 165,preLevelBoosterAllowPurchaseWithGold: 166,SelfExclusion: 167,alternativeLevelsForRetentionTaskForcev1000: 168,SeasonalStylePopupShownHalloween2021: 169,
	TiktokFollowReward: 170,PowerUpsOptInVideoRewardGiven: 171,forceDisableMemcache: 172,SeasonalStylePopupShownXmas2021: 173,MapBriefingAdsPromoBannerShown: 174,
	MapReward_EndlessDesert_Trapdoor: 175,charityGayGamingRewardCollected: 176,charityGayGamingSeen: 177
});

export default userFlags