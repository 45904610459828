// fiendQuests MUST be in same order as they are in game, match this to list in Global.AvailableFiendQuestList
const fiendQuestOrder = [
  'Blue2',
  'Yellow2',
  'Purple2',
  'Green1',
  'Red2',
  'Blue1',
  'Purple4',
  'Yellow3',
  'Green2',
  'Red4',
  'Yellow5'
];

export default fiendQuestOrder;