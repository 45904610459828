import { auth } from 'seriously-common';
import useRoles from './useRoles';

function useHasPermissions(permissions: string[]): boolean {
  const roles = useRoles();
  const hasPermissions = auth.hasPermissions(roles, permissions);
  return hasPermissions;
}

export default useHasPermissions;
