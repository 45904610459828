const achievements = [
  { id: '5', name: 'Daily Gift Calendars' },
  { id: '7', name: 'Defeat Slugs' },
  { id: '8', name: 'Events Score' },
  { id: '10', name: 'Activate Bombs' },
  { id: '11', name: 'Activate Converters' },
  { id: '12', name: 'Elite Levels' },
  { id: '13', name: 'Fiend Of Fortune' },
  { id: '14', name: 'Open Crates' },
  { id: '15', name: 'First Try Wins' },
  { id: '16', name: 'Fiend Styles' }
];

export default achievements;