import inventoryPrettyNames from './inventoryPrettyNames.json';

// RESOURCES
export const RESOURCE_GOLD = ['customUserData', 'gold'];
export const RESOURCE_DIAMONDS = ['customUserData', 'gems'];
export const RESOURCE_KEYS = ['customUserData', 'gachaKeys'];
export const RESOURCE_YELLOW_MITES = ['customUserData', 'upgradeResource0'];
export const RESOURCE_BLUE_MITES = ['customUserData', 'upgradeResource1'];
export const RESOURCE_GOLDEN_LEVELS = ['customUserData', 'dropGoldForLevels'];
export const RESOURCE_GOLDEN_TICKETS = ['customUserData', 'premiumGoldenEvents'];
export const RESOURCE_PENDING_UNLIMITED_ENERGY = ['energyData', 'pendingUnlimitedEnergy'];
export const RESOURCE_PENDING_VIP_TIME = ['energyData', 'pendingUnlimitedVIP'];
export const RESOURCE_ENERGY_CAP = ['energyData', 'energyBarCap'];
export const RESOURCE_PRE_LEVEL_BOOSTER_DOUBLE_BLOOM = ['customUserData', 'preLevelBoosterConverter'];
export const RESOURCE_PRE_LEVEL_FIRE_CRACKER = ['customUserData', 'preLevelBoosterClusterBomb'];
export const RESOURCE_PRE_LEVEL_BOOSTER_MAGIC_STAFF = ['customUserData', 'preLevelBoosterFury'];
export const RESOURCE_PRE_LEVEL_BOOSTER_ROCKET = ['customUserData', 'preLevelBoosterRocket'];
export const RESOURCE_PRE_LEVEL_BOOSTER_SLUG_SPRAY = ['customUserData', 'preLevelBoosterSlugAttack'];

// LIVE OPS EVENTS
export const LIVE_OPS_EVENTS_LAST_EVENT_TIME = ['liveOpsEvents', 'lastEventTimeUTC_fwdCompatible'];

// PLATFORMS
export const HAS_PLAYED_ON_AMAZON = ['customUserData', 'hasPlayedOnAmazon'];
export const HAS_PLAYED_ON_ANDROID = ['customUserData', 'hasPlayedOnAndroid'];
export const HAS_PLAYED_ON_FACEBOOK = ['customUserData', 'hasPlayedOnFacebook'];
export const HAS_PLAYED_ON_IOS = ['customUserData', 'hasPlayedOnIOS'];

// PROGRESS
export const WORLD_PROGRESS = ['progress'];
export const HERO_PROGRESS = ['heroArea', 'progress'];
export const EARTHWORM_LEVELS_COMPLETED_TOTAL = ['EarthwormQuests', 'totalLevelProgress'];
export const FIEND_QUEST_LEVELS_COMPLETED_TOTAL = ['Fiend_Quest_TotalProgress_NewFormat'];

// OTHER
export const ALL_TIME_MONEY_SPEND_IN_DOLLARS = ['purchaseInformation2', 'allTimeMoneySpendInDollars'];
export const COPPA_BIRTH_YEAR = ['customUserData', 'coppaBirthYear'];
export const SAVE_VERSION = ['SAVE_VERSION'];
export const FIRST_INSTALL_VERSION = ['FirstVersionAfterInstall'];
export const FIRST_INSTALL_DATE = ['FirstInstallDate'];
export const LAST_SESSION_DATE = ['customUserData', 'lastSaveDeviceTimeUTC'];
export const LAST_PURCHASE_DATE = ['purchaseInformation2', 'allTimeMoneySpendInDollars'];
export const PENDING_REDEEM_CODE = ['customUserData', 'pendingRedeemCode'];
export const BANNED_UNTIL_DATE = ['customUserData', 'bannedUntilDate'];
export const LATEST_SERVER_TIME_UTC = ['customUserData', 'latestServerTimeUTC'];
export const MARK_INVENTORY_FOR_RESET = ['MarkInventoryForReset'];
export const CURRENT_WIN_STREAK = ['customUserData', 'winStreak'];
export const LONGEST_WIN_STREAK = ['customUserData', 'longestWinStreak'];
export const CURRENT_COLLECTED_DAILY_GIFTS = ['customUserData', 'dailyGiftCollectedCounter'];
export const DAILY_GIFT_CALENDARS_COMPLETED_TOTAL = ['customUserData', 'dailyGiftsCalendarCompletedCounter'];
export const ACTIVE_SUPPORT_TICKET_ID = ['customUserData', 'activeSupportTicketId'];

export function mapToPrettyNameOrPath(path: string[]): string {
  const joinedPath = path.join('.');

  for (let prettyName of Object.entries(inventoryPrettyNames)) {
    const [key, value] = prettyName;
    
    if (key === joinedPath) {
      return value;
    }
  }

  return joinedPath;
}
